<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Usuário</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :loading="loadingUsers"
          no-data-text="Sem dados no momento"
          no-results-text="Sem dados para sua busca"
          :header-props="{ sortByText: 'Ordenar por' }"
          loading-text="Carregando dados"
          class="elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="1100px">
        <v-card elevation="0">
          <v-card-title>
            <span class="headline">Cadastro de Usuário</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    label="Nome Completo"
                    v-model="user.name"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Rota Inicial"
                    v-model="user.initialRoute"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Email"
                    required
                    v-model="user.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Usuário"
                    required
                    v-model="user.username"
                  ></v-text-field>
                </v-col>
                <v-col v-if="user.id" cols="4" sm="3" md="3">
                  <v-checkbox
                    label="Atualizar Senha?"
                    required
                    v-model="updatePass"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="updatePass || !user.id" cols="8" sm="3" md="3">
                  <v-text-field
                    label="Password"
                    type="password"
                    v-model="user.password"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="user.systemClientId"
                    :items="companies"
                    item-text="fantasyName"
                    item-value="id"
                    label="Empresa/Filial*"
                    required
                    @change="getGroups()"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="user.groupId"
                    :items="groupUsers"
                    item-text="name"
                    item-value="id"
                    label="Perfil"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()"> Fechar </v-btn>
            <v-btn :loading="loading" color="success" @click="createUser()">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import UserDialog from "@/components/UserDialog";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  name: "Users",
  components: { InternalMenu, PageHeader, UserDialog },
  data: () => ({
    title: "Usuários",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Perfís de Acesso",
            to: "/user/group-users",
            permission: "super_admin",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Usuários",
        disabled: true,
        href: "/users",
      },
    ],
    users: [],
    groupUsers: [],
    companies: [],
    search: "",
    updatePass: false,
    user: {
      id: "",
      name: "",
      email: "",
      username: null,
      password: null,
      companyId: "pedbem",
      systemClientId: null,
      groupId: null,
      initialRoute: "/",
    },
    loading: false,
    loadingUsers: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    openDialog: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      { text: "Email", value: "email" },
      { text: "Perfil", value: "group.name" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    getUsers() {
      this.loadingUsers = true;
      http.get("users").then((data) => {
        this.users = data.data;
        this.loadingUsers = false;
      });
    },
    getCompanies() {
      http.get("tenants").then((data) => {
        this.companies = data.data;
      });
    },
    getGroups() {
      if (!this.user.systemClientId) return;
      http
        .get(`user/group-users?filter=systemClientId||eq||${this.user.systemClientId}`)
        .then((data) => {
          this.groupUsers = data.data;
        });
    },
    createUser() {
      this.loading = true;
      if (this.user.id) {
        if (!this.updatePass) {
          delete user.password;
        }
        http.patch(`users/${this.user.id}`, this.user).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Usuário Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loading = false;
            this.closeDialog();
            this.getUsers();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text =
              "Ocorreu um erro ao Atualizar o Usuário. Tente Novamente";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        http.post("users", this.user).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Usuário Criado com Sucesso!";
            this.snackbar.opened = true;
            this.loading = false;
            this.closeDialog();
            this.getUsers();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text =
              "Ocorreu um erro ao Cadastrar o Usuário. Tente Novamente";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.user = "";
      this.updatePass = false;
    },
    editItem(item) {
      this.user = item;
      this.getGroups();
      this.openDialog = true;
    },
  },
  mounted() {
    this.getUsers();
    this.getCompanies();
  },
});
</script>
